<template>
  <div style="padding: 1rem;">
    <b-card>
      <b-row class="mb-1 pr-0">
        <b-col>
          <!-- <h4>{{$t('Request edition')}}</h4>
          <h6>{{$t('Maintenance to Property', {house: (requestData.housingnumber || '') + ' | ' +(requestData.resortsname || '')})}}</h6> -->
          <h4>{{$t('Edit')}} {{$t('maintenance requests')}}</h4>
        </b-col>
        <b-col>
          <b-button class="float-right" size="sm" variant="warning" :to="{ name: 'my-property', params: {index:1, info: this.$t('maintenance requests')} }">
            <feather-icon icon="ArrowLeftIcon" size="16" class="align-middle" style="color: white" />
          </b-button>
        </b-col>
        <b-col cols="12" class="mt-2">
          <b-alert show class="p-1 text-center" :variant="badgeStatus(requestData).variant">
            {{ $t('maintenance requests') }} <strong>{{ badgeStatus(requestData).statusName }}</strong>
          </b-alert>
          <div class="border-custom rounded p-1" v-if="!!requestData.reviewData">
            <b-row align-v="center">
              <b-col cols="12">
                <!-- <h6>Datos de </h6> -->
              </b-col>
              <b-col>
                <label class="font-weight-bolder">{{ badgeStatus(requestData).statusName }} {{('By')}}:</label><br>
                {{ requestData.reviewData.authFullname }} <br>

                <label class="font-weight-bolder">{{$t('Reviewed at')}}:</label><br>
                {{ formatThisDate(requestData.reviewData.createdate) }}
              </b-col>
              <b-col md="10" class="border-left">
                <label class="font-weight-bolder">{{$t('Reason')}}:</label><br>
                {{ requestData.reviewData.notes }}
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>

      <div class="text-center my-2" v-if="isLoadingControlRequestData">
        <b-spinner class="align-middle" label="Loading..." variant="success"></b-spinner>
        <strong> {{$t('loading')}}... </strong>
      </div>

      <RequestForm
        v-if="!!requestData.id"
        :isLoadingControlAccessData="isLoadingControlRequestData"
        :isSavingControlAccessData="isSavingControlRequestData"
        :requestData="requestData"
        :originalRequestData="requestData"
        @save-register="saveRecord"
      />

    </b-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RequestForm from '@/modules/myProperty/components/MaintenanceRequest/RequestForm'
import { showAlertMessage, formatDate } from '@/helpers/helpers'
import { utils } from "@/modules/owners/mixins/utils"

export default {
  mixins:[ utils ],
  async created() {
    await this.init()
  },
  components: {
    RequestForm,
  },
  data() {
    return {
      isLoadingControlRequestData: false,
      isSavingControlRequestData: false,
      requestData: {
        id:null,
        reason: null,
        description: null,
        startDate: null,
        endDate: null,

        provider: null,
        providerName: null,
        providerPhone: null,
        providerEmail: null,

        dateOut: null,
        timeIn: null,
        timeOut: null,

        files:[],
        images:[],
      },
      originalRequestData: null
    }
  },
  computed:{
    ...mapState('auth', ['user', 'myContracts']),
  },
  methods:{
    ...mapActions('myProperty', ['fetchMaintenanceRequests', 'updateMaintenanceRequest']),
    async init(){
      const idRequest = this.$route.params.id
      if(this.isANumber(idRequest)){
        this.isLoadingControlRequestData = true
        const accessRegister = await this.fetchMaintenanceRequests({idRequest})
        this.requestData = structuredClone(accessRegister)
        this.originalRequestData = structuredClone(accessRegister)
        this.isLoadingControlRequestData = false

        if (['2', '3'].includes(accessRegister?.status)){
          this.$router.push({name: 'maintenance-request', params: { id: accessRegister.id } })
          await this.pushData('onClick', 'goToShowMaintenanceDataRequest', 'goToShowMaintenanceDataRequest', this.$route.name)
        }
      }
    },
    isANumber(string){
      return string ? !isNaN( string ) && string > 0 : false
    },
    formatThisDate(date){
      return formatDate({date, language: this.user?.languageName})
    },
    async saveRecord(data){
      // validar si es admin desde mixin en utils
      if( this.isAdmin ){
        return this.$swal.fire({icon: "error", title: `${this.$t("Restricted Access")}`, html: `${this.$t("Text Restricted Access Admin")}`});
      }
      this.isSavingControlRequestData = true
      data.idOwner = this.user.idOwner
      data.idContract = this.myContracts.contractSelected.id
      data.status = 1
      const response = await this.updateMaintenanceRequest(data)
      if (response) {
        showAlertMessage(this.$t('Access updated'), 'InfoIcon', `🎉🎉 ${this.$t('Register updated')}`, 'success', 4000, 'bottom-right')
        await this.pushData('onClick', 'updateMaintenanceDataRequest', 'updateMaintenanceDataRequest', this.$route.name) // push a back del registro
        this.$router.push({name: 'my-property', params: {index:1, info: this.$t('maintenance requests')}})
      }
      if( !response ){
        showAlertMessage( this.$t('something failed'), 'InfoIcon', this.$t(`try again`), 'danger', 4000, 'bottom-right')
      }
      this.isSavingControlRequestData = false
    },
		badgeStatus(request){
			const { status } = request

			let variant =  'outline-info'
			let statusName = this.$t("Pending")

			if(status === '1' ){
				variant = 'info'
				statusName = this.$t("Pending") //  1-> Pendiente
			}

			if(status === '2' ){
				variant = 'success'
				statusName = this.$t("Confirmed") // 2-> es confirmada
			}

			if( status === '3' ){
				variant = 'danger'
				statusName = this.$t("Cancelled")
			}

			if( status === '4' ){
				variant = 'warning'
				statusName = this.$t("Rejected")
			}

			return {variant, statusName}
		},
  },
}
</script>

<style lang="scss" scoped>

.border-custom{
  border: 1px solid #ebe9f1 !important;
  transition: all 0.5s ease;
}

.border-custom:hover{
  border: 1px solid rgb(169, 168, 168) !important;
  transition: all 0.5s ease;
}
</style>